import { JsonObject, JsonProperty } from 'json2typescript';
import { ResponseCommon } from '../utils';

@JsonObject('FeedbackCoverageEntry')
export class FeedbackCoverageEntry {
  @JsonProperty('value', String)
  value: string;

  @JsonProperty('percentage', Number)
  percentage: number;
}

@JsonObject('FeedbackCoverageResponse')
export class FeedbackCoverageResponse extends ResponseCommon {
  @JsonProperty('top_five_high', [FeedbackCoverageEntry])
  topFiveHigh: FeedbackCoverageEntry[];

  @JsonProperty('top_five_low', [FeedbackCoverageEntry])
  topFiveLow: FeedbackCoverageEntry[];

  @JsonProperty('coverage_percentage', Number)
  coveragePercentage: number;

  @JsonProperty('unique_values', Number)
  uniqueValues: number;
}
