import { JsonObject, JsonProperty } from 'json2typescript';
import { ResponseCommon } from '../utils';

@JsonObject('UsersPerformanceEntry')
export class UsersPerformanceEntry {
  @JsonProperty('username', String)
  username: string;

  @JsonProperty('feedback_given', Number)
  feedbackGiven: number;

  @JsonProperty('consensus', Number)
  consensus: number;
}

@JsonObject('UsersPerformanceResponse')
export class UsersPerformanceResponse extends ResponseCommon {
  @JsonProperty('data', [UsersPerformanceEntry])
  data: UsersPerformanceEntry[];
}
