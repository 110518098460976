import { JsonObject, JsonProperty } from 'json2typescript';
import { ResponseCommon } from '../utils';
import { JsonCustomConvert, JsonConverter } from 'json2typescript';

export class DecisionsMetricsEntry {
  [key: string]: never;
}

@JsonConverter
class DecisionsMetricsEntryConverter implements JsonCustomConvert<DecisionsMetricsEntry> {
  serialize(data: DecisionsMetricsEntry) {
    return data;
  }

  deserialize(data: never): DecisionsMetricsEntry {
    const entry = new DecisionsMetricsEntry();
    Object.assign(entry, data);
    return entry;
  }
}

@JsonObject('DecisionsMetricsResponse')
export class DecisionsMetricsResponse extends ResponseCommon {
  @JsonProperty('chart_data', [DecisionsMetricsEntryConverter])
  chartData: DecisionsMetricsEntry[];
}
