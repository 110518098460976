export default class FilterOperators {
  static IS_OPERATOR = 'is';
  static IS_NOT_OPERATOR = 'is not';
  static STARTS_WITH_OPERATOR = 'starts with';
  static NOT_STARTS_WITH_OPERATOR = 'not starts with';
  static CONTAINS_OPERATOR = 'contains';
  static NOT_CONTAINS_OPERATOR = 'not contains';
  static EXISTS_OPERATOR = 'exists';
  static EXISTS_NOT_OPERATOR = "doesn't exist";
  static LOWER_THAN_OPERATOR = 'lt';
  static LOWER_THAN_EQUAL_OPERATOR = 'lte';
  static GREATER_THAN_OPERATOR = 'gt';
  static GREATER_THAN_EQUAL_OPERATOR = 'gte';
}
