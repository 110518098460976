import { JsonObject, JsonProperty } from 'json2typescript';
import { ResponseCommon } from '../utils';

@JsonObject('ArcannaSavingsResponse')
export class ArcannaSavingsResponse extends ResponseCommon {
  @JsonProperty('hours_saved', Number)
  hoursSaved: number;

  @JsonProperty('cost_saved', Number)
  costSaved: number;
}
