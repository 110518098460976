import { TFilterItem } from '@arcanna/components';
import moment from 'moment';
import { createStore } from 'zustand';

type TFiltersState = {
  startDate: Date | null;
  endDate: Date | null;
};

export type TECDIOverviewState = {
  filtersState: TFiltersState;
  advancedFilters: TFilterItem[];
  setDates: (startDate: Date | null, endDate: Date | null) => void;
  setAdvancedFilters: (advancedFilters: TFilterItem[]) => void;
};

export type TECDIOverviewStore = ReturnType<typeof createECDIOverviewStore>;

export type TECDIOverviewPageLoadFilters = {
  startDate: string | null;
  endDate: string | null;
  advancedFilters: TFilterItem[];
};

type TCreateECDIOverviewStore = {
  pageLoadFilters: TECDIOverviewPageLoadFilters;
};

const createECDIOverviewStore = ({ pageLoadFilters }: TCreateECDIOverviewStore) => {
  return createStore<TECDIOverviewState>()((set) => ({
    filtersState: {
      startDate: pageLoadFilters.startDate ? moment(pageLoadFilters.startDate).toDate() : null,
      endDate: pageLoadFilters.endDate ? moment(pageLoadFilters.endDate).toDate() : null
    },
    advancedFilters: pageLoadFilters.advancedFilters,
    setDates: (startDate, endDate) =>
      set((state) => ({
        ...state,
        filtersState: {
          ...state.filtersState,
          startDate,
          endDate
        }
      })),
    setAdvancedFilters: (advancedFilters) =>
      set((state) => ({
        ...state,
        advancedFilters
      }))
  }));
};

export default createECDIOverviewStore;
