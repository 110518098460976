import { EIcon, Icon } from '@arcanna/generic';
import { Stack, StackProps, Tooltip, Typography, useTheme } from '@mui/material';
import { ReactNode, useMemo } from 'react';

type TGenericOverviewCardProps = {
  title?: string;
  infoTooltipText?: string;
  actions?: ReactNode;
  children: ReactNode;
} & StackProps;

function GenericOverviewCard({ title, infoTooltipText, actions, children, ...stackProps }: TGenericOverviewCardProps) {
  const { palette } = useTheme();

  const shouldRenderHeader = useMemo(() => title || infoTooltipText || actions, [title, infoTooltipText, actions]);

  return (
    <Stack bgcolor={palette.secondary[900]} borderRadius="9px" gap="12px" p={2} {...stackProps}>
      {shouldRenderHeader && (
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" gap={1} justifyContent={'center'} alignItems={'center'}>
            {title && (
              <Typography variant="subtitle1" color={palette.secondary[200]}>
                {title}
              </Typography>
            )}
            {infoTooltipText && (
              <Tooltip title={<div dangerouslySetInnerHTML={{ __html: infoTooltipText }}></div>} placement="bottom" arrow>
                <Stack
                  component="span"
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  color={palette.secondary[400]}
                >
                  <Icon name={EIcon.InfoCircle} fontSize="small" />
                </Stack>
              </Tooltip>
            )}
          </Stack>
          {actions}
        </Stack>
      )}
      {children}
    </Stack>
  );
}

export default GenericOverviewCard;
