import { Modal } from '@arcanna/generic';
import { useTranslation } from 'react-i18next';
import StyledCustomLabelPillMenu from './StyledCustomLabelPillMenu.styles';
import AddCustomLabelItem from './AddCustomLabelItem';

type TCustomLabelPillMenuProps = {
  anchorEl: HTMLElement | null;
  isMenuOpen: boolean;
  handleClose: () => void;
  selectedColor: string;
  setSelectedColor: (color: string) => void;
  newLabel: string;
  customError: string;
  handleSave: () => void;
  validateAndSet: (value: string) => void;
  renderType?: 'pillMenu' | 'modal';
};

function CustomLabelPillMenu({
  anchorEl,
  isMenuOpen,
  handleClose,
  selectedColor,
  setSelectedColor,
  newLabel,
  customError,
  handleSave,
  validateAndSet,
  renderType
}: TCustomLabelPillMenuProps) {
  const { t } = useTranslation('common');

  return (
    <>
      {renderType === 'pillMenu' ? (
        <StyledCustomLabelPillMenu
          anchorEl={anchorEl}
          open={isMenuOpen}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          <AddCustomLabelItem
            handleClose={handleClose}
            selectedColor={selectedColor}
            setSelectedColor={setSelectedColor}
            newLabel={newLabel}
            customError={customError}
            handleSave={handleSave}
            validateAndSet={validateAndSet}
          />
        </StyledCustomLabelPillMenu>
      ) : (
        <Modal open={isMenuOpen} onClose={handleClose} title={t('addNewDecision')}>
          <AddCustomLabelItem
            handleClose={handleClose}
            selectedColor={selectedColor}
            setSelectedColor={setSelectedColor}
            newLabel={newLabel}
            customError={customError}
            handleSave={handleSave}
            validateAndSet={validateAndSet}
          />
        </Modal>
      )}
    </>
  );
}

export default CustomLabelPillMenu;
