import { DecisionsBreakdownResponse, GenericOverviewRequest } from '@arcanna/models/Overview';
import { ResourceWrapper, ResponseCommon } from '@arcanna/models/utils';
import { Serializer } from '@arcanna/utils';
import axios, { AxiosResponse } from 'axios';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { overviewKeys } from 'src/_srcMUI/requests/Overview/keys';
import { showErrorNotification } from 'src/components/shared/utilities/notification';
import { config } from 'src/config';

type TUseDecisionsBreakdownProps = {
  jobId: number;
  payload: GenericOverviewRequest;
};

function useDecisionsBreakdown({ jobId, payload }: TUseDecisionsBreakdownProps) {
  // OTHER HOOKS
  const { t } = useTranslation(['requests']);

  // SETUP
  const URL = useMemo(() => config.api.overview.decisionsBreakdown, []);
  const axiosFunction = useCallback(
    (body: object) =>
      axios
        .post<ResourceWrapper<DecisionsBreakdownResponse>>(URL, body)
        .then((response) => Serializer.getInstance().deserializeCommonResponse(response, DecisionsBreakdownResponse)),
    [URL]
  );

  // PAYLOAD
  const payloadSerialized = useMemo(() => Serializer.getInstance().serializeObject(payload), [payload]);

  // QUERY
  return useQuery(overviewKeys.decisionsBreakdown({ jobId, payload }), () => axiosFunction(payloadSerialized), {
    onError: (error: AxiosResponse<ResourceWrapper<ResponseCommon>>) => {
      if (error.data?.resource?.request?.reason) {
        showErrorNotification('Error', error.data.resource.request.reason);
      } else {
        showErrorNotification(
          t('requests:ecdiOverview.getDecisionBreakdown.error.title'),
          t('requests:ecdiOverview.getDecisionBreakdown.error.subtitle')
        );
      }
    }
  });
}

export default useDecisionsBreakdown;
