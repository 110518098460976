import { Stack, Tab, Tabs, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import GenericOverviewCard from '../GenericOverviewCard';
import { EActionCenter, EActionCenterSubTypes } from './ActionCenter.types';
import { getActionCenterTabs } from './ActionCenter.utils';
import { ActionCard } from './components';
import { EmptyState, Spinner } from '@arcanna/generic';

type TActionCenterAction = {
  type: EActionCenter;
  subtype: EActionCenterSubTypes;
  value: number | null;
};

type TActionCenterProps = {
  actions: TActionCenterAction[];
  isLoading: boolean;
};
function ActionCenter({ actions, isLoading }: TActionCenterProps) {
  const [currentTab, setCurrentTab] = useState<EActionCenter>(EActionCenter.ALL);

  const tabs = useMemo(() => getActionCenterTabs(), []);

  const filteredData = useMemo(() => {
    if (currentTab === EActionCenter.ALL) return actions;
    return actions.filter((action) => action.type === currentTab);
  }, [currentTab, actions]);

  return (
    <GenericOverviewCard
      height={'254px'}
      width={'100%'}
      title="Action Center"
      infoTooltipText="Look through important actions and recommendations tailored for this job. Click on a card to take action."
      position={'relative'}
    >
      {isLoading && <Spinner isOverlay />}

      {actions.length > 0 ? (
        <Stack gap={3}>
          <Tabs value={currentTab} onChange={(_, value) => setCurrentTab(value)}>
            {tabs.map((tab) => (
              <Tab
                disableRipple
                key={tab.value}
                label={
                  <Typography variant="body2" fontWeight={500} color="inherit">
                    {tab.label} (
                    {tab.value === EActionCenter.ALL
                      ? actions.length
                      : actions.filter((action) => action.type === tab.value).length}
                    )
                  </Typography>
                }
                value={tab.value}
              />
            ))}
          </Tabs>
          <Stack direction="row" gap={'16px'} sx={{ overflowX: 'scroll' }}>
            {filteredData.length > 0 ? (
              filteredData.map((action, index) => (
                <ActionCard key={index} type={action.type} subtype={action.subtype} value={action.value ?? 0} />
              ))
            ) : (
              <EmptyState marginTop={'0'} title={'No actions needed.'} subtitle={'You can focus on other tasks.'} />
            )}
          </Stack>
        </Stack>
      ) : (
        <EmptyState title={'No actions needed.'} subtitle={'You can focus on other tasks.'} />
      )}
    </GenericOverviewCard>
  );
}

export default ActionCenter;
