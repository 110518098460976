import { JsonObject, JsonProperty } from 'json2typescript';
import { ResponseCommon } from '../utils';
import { EActionCenter, EActionCenterSubTypes } from '../../shared/components/Overview/ActionCenter/ActionCenter.types';

export class ActionCenterEntry {
  @JsonProperty('type', String)
  type: EActionCenter;

  @JsonProperty('subtype', String)
  subtype: EActionCenterSubTypes;

  @JsonProperty('value', Number)
  value: number | null;
}

@JsonObject('ActionCenterResponse')
export class ActionCenterResponse extends ResponseCommon {
  @JsonProperty('data', [ActionCenterEntry])
  data: ActionCenterEntry[];
}
