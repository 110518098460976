import { Box, Stack, Typography, useTheme } from '@mui/material';

type ConsensusBarProps = {
  consensus: number;
};

enum CONFIDENCE_SCORE_THRESHOLDS {
  LOW = 25,
  MEDIUM = 60,
  HIGH = 75
}

export default function ConsensusBar({ consensus }: ConsensusBarProps) {
  let barColor = '';
  const { palette } = useTheme();

  if (consensus <= CONFIDENCE_SCORE_THRESHOLDS.LOW) {
    barColor = palette.error[700];
  }

  if (CONFIDENCE_SCORE_THRESHOLDS.LOW < consensus && consensus <= CONFIDENCE_SCORE_THRESHOLDS.MEDIUM) {
    barColor = palette.warning[700];
  }

  if (CONFIDENCE_SCORE_THRESHOLDS.MEDIUM < consensus && consensus <= CONFIDENCE_SCORE_THRESHOLDS.HIGH) {
    barColor = palette.info[700];
  }

  if (CONFIDENCE_SCORE_THRESHOLDS.HIGH < consensus) {
    barColor = palette.success[700];
  }
  return (
    <Stack direction={'row'} spacing={'10px'} justifyContent={'space-between'} alignItems={'center'}>
      <Typography variant={'subtitle2'} color={palette.secondary[100]} width={'40px'}>
        {consensus.toLocaleString()}%
      </Typography>
      <Box sx={{ width: '100%', height: '4px', backgroundColor: palette.secondary[700], borderRadius: '4px' }}>
        <Box sx={{ width: consensus + '%', height: '4px', backgroundColor: barColor, borderRadius: '4px' }} />
      </Box>
    </Stack>
  );
}
