import { JsonObject, JsonProperty } from 'json2typescript';
import { ResponseCommon } from '../utils';

@JsonObject('DecisionsBreakdownEntry')
export class DecisionsBreakdownEntry {
  @JsonProperty('label_id', String)
  label_id: string;

  @JsonProperty('label_number', Number)
  labelNumber: number;

  @JsonProperty('label_percent', Number)
  labelPercent: number;
}

@JsonObject('DecisionsBreakdownResponse')
export class DecisionsBreakdownResponse extends ResponseCommon {
  @JsonProperty('data', [DecisionsBreakdownEntry])
  data: DecisionsBreakdownEntry[];
}
