import { useContext } from 'react';
import { TECDIOverviewState } from './ECDIOverviewStore';
import { ECDIOverviewContext } from './ECDIOverviewContextProvider';
import { useStore } from 'zustand';

function useECDIOverviewStore<T>(selector: (state: TECDIOverviewState) => T): T {
  const store = useContext(ECDIOverviewContext);

  if (!store) {
    throw new Error('Missing ECDIOverviewContext.Provider in the tree');
  }

  return useStore(store, selector);
}

export default useECDIOverviewStore;
