export default function Dollar() {
  return (
    <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        /* eslint-disable-next-line max-len */
        d="M4.05682 13.2727V0.545454H4.87216V13.2727H4.05682ZM6.80114 4.36364C6.74148 3.85985 6.49953 3.46875 6.07528 3.19034C5.65104 2.91193 5.13068 2.77273 4.5142 2.77273C4.06345 2.77273 3.66903 2.84564 3.33097 2.99148C2.99621 3.13731 2.73438 3.33783 2.54545 3.59304C2.35985 3.84825 2.26705 4.13826 2.26705 4.46307C2.26705 4.73485 2.33168 4.96851 2.46094 5.16406C2.59351 5.3563 2.76255 5.51705 2.96804 5.64631C3.17353 5.77225 3.38897 5.87666 3.61435 5.95952C3.83973 6.03906 4.04688 6.10369 4.2358 6.15341L5.26989 6.43182C5.53504 6.50142 5.83002 6.59754 6.15483 6.72017C6.48295 6.8428 6.79616 7.01018 7.09446 7.2223C7.39607 7.43111 7.64465 7.69957 7.8402 8.0277C8.03575 8.35582 8.13352 8.75852 8.13352 9.2358C8.13352 9.78598 7.98935 10.2831 7.70099 10.7273C7.41596 11.1714 6.99834 11.5244 6.44815 11.7862C5.90128 12.0481 5.23674 12.179 4.45455 12.179C3.72538 12.179 3.09399 12.0613 2.56037 11.826C2.03007 11.5907 1.61245 11.2625 1.30753 10.8416C1.00592 10.4207 0.835227 9.93182 0.795455 9.375H2.06818C2.10133 9.75947 2.23059 10.0777 2.45597 10.3295C2.68466 10.5781 2.97301 10.7637 3.32102 10.8864C3.67235 11.0057 4.05019 11.0653 4.45455 11.0653C4.92519 11.0653 5.34777 10.9891 5.7223 10.8366C6.09683 10.6809 6.39347 10.4654 6.61222 10.1903C6.83097 9.91193 6.94034 9.58712 6.94034 9.21591C6.94034 8.87784 6.84588 8.60275 6.65696 8.39062C6.46804 8.1785 6.21946 8.00616 5.91122 7.87358C5.60298 7.741 5.26989 7.625 4.91193 7.52557L3.65909 7.16761C2.86364 6.93892 2.2339 6.61245 1.76989 6.18821C1.30587 5.76397 1.07386 5.20881 1.07386 4.52273C1.07386 3.95265 1.22798 3.45549 1.53622 3.03125C1.84777 2.60369 2.26539 2.27225 2.78906 2.03693C3.31605 1.7983 3.90436 1.67898 4.55398 1.67898C5.21023 1.67898 5.79356 1.79664 6.30398 2.03196C6.81439 2.26397 7.21875 2.58215 7.51705 2.98651C7.81866 3.39086 7.97775 3.8499 7.99432 4.36364H6.80114Z"
        fill="currentColor"
      />
    </svg>
  );
}
