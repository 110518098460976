import { TFilterItem, TruncatedText } from '@arcanna/components';
import { Table } from '@arcanna/generic';
import { Palette } from '@mui/material/styles';
import { createColumnHelper } from '@tanstack/react-table';
import { TEventStructuredRow } from './EventStructuredTab.types';

type TGetColumnsParams = {
  palette: Palette;
  activeFilters: TFilterItem[];
  addAdvancedFilter: (filterItem: TFilterItem) => void;
};

function getColumns({ palette, activeFilters, addAdvancedFilter }: TGetColumnsParams) {
  const columnHelper = createColumnHelper<TEventStructuredRow>();

  return [
    columnHelper.accessor('name', {
      header: 'Name',
      cell: ({ row }) => <TruncatedText variant="subtitle2" text={row.original.name} />,
      minSize: 80,
      maxSize: 120
    }),
    columnHelper.accessor('type', {
      header: 'Type',
      cell: ({ row }) => <TruncatedText variant="subtitle2" color={palette.secondary[300]} text={row.original.type ?? ''} />,
      minSize: 30,
      maxSize: 50
    }),
    columnHelper.accessor('value', {
      header: 'Value',
      cell: ({ row, tableSize, customRowSizeValue }) => (
        <Table.GeneralCellWithFilters
          columnName={row.original.name}
          filterSource={row.original.name}
          value={row.original.value}
          activeFilters={activeFilters}
          addAdvancedFilter={addAdvancedFilter}
          tableSize={tableSize}
          customRowSizeValue={customRowSizeValue}
          valueType={row.original.type}
        />
      )
    })
  ];
}

export { getColumns };
