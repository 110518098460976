import { JsonConverter, JsonCustomConvert, JsonObject, JsonProperty } from 'json2typescript';
import { ResponseCommon } from '../utils';

export class AccuracyMetricsEntry {
  @JsonProperty('date', String)
  date: string;

  @JsonProperty('accuracy', Number)
  accuracy: number;

  @JsonProperty('f1_score', Number)
  f1_score: number;
}

@JsonConverter
class AccuracyMetricsConverter implements JsonCustomConvert<AccuracyMetricsEntry> {
  serialize(data: AccuracyMetricsEntry) {
    return data;
  }

  deserialize(data: never): AccuracyMetricsEntry {
    const entry = new AccuracyMetricsEntry();
    Object.assign(entry, data);
    return entry;
  }
}

@JsonObject('AccuracyMetricsResponse')
export class AccuracyMetricsResponse extends ResponseCommon {
  @JsonProperty('chart_data', [AccuracyMetricsConverter])
  chartData: AccuracyMetricsEntry[];
}
