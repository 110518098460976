import { EventExplorerFiltersFieldsRecord } from '../../shared/models/event-explorer/EventExplorerFiltersFieldsRecord';
import { EventExplorerStaticRuleFilterRecord } from '../../shared/models/event-explorer/EventExplorerFiltersValuesRequest';
import { CustomLabel } from '../../shared/models';
import { UNDECIDED_CONSENSUS_ID, undecidedConsensus } from '../Main/Jobs/helper';

export function mapToAntdSorter(sorter: string) {
  switch (sorter) {
    case 'asc':
      return 'ascend';
    case 'desc':
      return 'descend';
    default:
      return 'ascend';
  }
}

export function mapFromSorterAntd(sorter: 'ascend' | 'descend' | null) {
  switch (sorter) {
    case 'ascend':
      return 'asc';
    case 'descend':
      return 'desc';
    default:
      return 'asc';
  }
}

export const CLUSTER_ID_COLUMN_NAME = 'arcanna.clustering.cluster_id';
export const ARCANNA_RESULT_FIELD = 'arcanna.result';
export const ARCANNA_CONSENSUS_FIELD = 'arcanna.consensus';
export const ARCANNA_NO_DECISION = 'no_decision';
export const ARCANNA_BUCKET_ID = 'arcanna.bucket_id';
export const ARCANNA_NO_DECISION_DISPLAY_VALUE = 'No decision';
export const resultFilterField = {
  source: 'arcanna.result',
  name: 'result',
  operators: ['is', 'is not']
};

type IsFilterPresent = {
  operator: string;
  columnName: string;
  appliedAdvancedFilters: EventExplorerStaticRuleFilterRecord[];
  filterFields: EventExplorerFiltersFieldsRecord[] | undefined;
  value: string;
};
export function isFilterPresent({ operator, columnName, appliedAdvancedFilters, filterFields, value }: IsFilterPresent) {
  if (filterFields) {
    const associatedFilter = [...filterFields, resultFilterField].find(
      (filter) => filter.name === columnName || filter.source === columnName
    );

    if (!associatedFilter) {
      return true;
    }

    return appliedAdvancedFilters.find(
      (f: EventExplorerStaticRuleFilterRecord) => f.field === columnName && f.operator === operator && f.value === value
    );
  }
}

export function getResultDisplayValue(value: string | string[], customLabels: CustomLabel[]) {
  if (value === ARCANNA_NO_DECISION) {
    return ARCANNA_NO_DECISION_DISPLAY_VALUE;
  }

  if (value === UNDECIDED_CONSENSUS_ID) {
    return undecidedConsensus.name;
  }

  return customLabels?.find((customLabel) => customLabel.id === value)?.name;
}
