import { JsonObject, JsonProperty } from 'json2typescript';
import { GenericOverviewRequest } from './GenericOverviewRequest';

@JsonObject('FeedbackCoverageRequest')
export class FeedbackCoverageRequest extends GenericOverviewRequest {
  @JsonProperty('field', String)
  field: string | null;
}

type TConstructFeedbackCoverageRequest = {
  jobId: FeedbackCoverageRequest['jobId'];
  dateFrom: FeedbackCoverageRequest['dateFrom'];
  dateTo: FeedbackCoverageRequest['dateTo'];
  filters: FeedbackCoverageRequest['filters'];
  field: FeedbackCoverageRequest['field'];
};

export function constructFeedbackCoverageRequest({
  jobId,
  dateFrom,
  dateTo,
  filters,
  field
}: TConstructFeedbackCoverageRequest): FeedbackCoverageRequest {
  const instance = new FeedbackCoverageRequest();
  instance.jobId = jobId;
  instance.dateFrom = dateFrom;
  instance.dateTo = dateTo;
  instance.filters = filters;
  instance.field = field;

  return instance;
}
