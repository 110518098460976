enum EActionCenter {
  ALL = 'all',
  GIVE_FEEDBACK = 'give_feedback',
  ADJUST_FEEDBACK = 'adjust_feedback',
  TRAINING = 'training'
}

enum EActionCenterSubTypes {
  // Subtypes for GIVE_FEEDBACK
  OUTLIERS = 'outliers',
  LOW_CONFIDENCE_SCORE = 'low_confidence_score',

  // Subtypes for ADJUST_FEEDBACK
  UNDECIDED_CONSENSUS = 'undecided_consensus',

  // Subtypes for TRAINING
  NEEDS_TRAINING = 'needs_training'
}

type TActionCenterTypesMapped = {
  [EActionCenter.GIVE_FEEDBACK]: EActionCenterSubTypes.OUTLIERS | EActionCenterSubTypes.LOW_CONFIDENCE_SCORE;
  [EActionCenter.ADJUST_FEEDBACK]: EActionCenterSubTypes.UNDECIDED_CONSENSUS;
  [EActionCenter.TRAINING]: EActionCenterSubTypes.NEEDS_TRAINING;
};

export { EActionCenter, EActionCenterSubTypes, type TActionCenterTypesMapped };
