import { JsonObject, JsonProperty } from 'json2typescript';
import { FilterRecord } from 'src/components/shared/models/filters/FilterRecord';
import { RequestCommon } from '../utils';

@JsonObject('GenericOverviewRequest')
export class GenericOverviewRequest extends RequestCommon {
  @JsonProperty('job_id', Number)
  jobId: number;

  @JsonProperty('date_from', String)
  dateFrom: string | null;

  @JsonProperty('date_to', String)
  dateTo: string | null;

  @JsonProperty('filters', [FilterRecord])
  filters: FilterRecord[];
}

type TConstructGenericOverviewRequest = {
  jobId: GenericOverviewRequest['jobId'];
  dateFrom: GenericOverviewRequest['dateFrom'];
  dateTo: GenericOverviewRequest['dateTo'];
  filters: GenericOverviewRequest['filters'];
};

export function constructGenericOverviewRequest({
  jobId,
  dateFrom,
  dateTo,
  filters
}: TConstructGenericOverviewRequest): GenericOverviewRequest {
  const instance = new GenericOverviewRequest();
  instance.jobId = jobId;
  instance.dateFrom = dateFrom;
  instance.dateTo = dateTo;
  instance.filters = filters;

  return instance;
}
