import React from 'react';

const ECDIFieldsTab = React.lazy(() => import('./components/ECDIFieldsTab'));
const ECDIExplorerTab = React.lazy(() => import('./components/ECDIExplorerTab'));
const ECDIExplorerKBTab = React.lazy(() => import('./components/ECDIExplorerKBTab'));

export enum EECDIExplorerTabs {
  FIELDS = 'fields',
  EVENTS = 'events',
  KB_VIEW = 'kbView'
}

export const getTabsConfig = () => [
  {
    tabKey: EECDIExplorerTabs.FIELDS,
    title: 'Fields',
    component: ECDIFieldsTab
  },
  {
    tabKey: EECDIExplorerTabs.EVENTS,
    title: 'Events',
    component: ECDIExplorerTab
  },
  {
    tabKey: EECDIExplorerTabs.KB_VIEW,
    title: 'Review',
    component: ECDIExplorerKBTab
  }
];
