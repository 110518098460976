import { FeedbackCoverageRequest, FeedbackCoverageResponse } from '@arcanna/models/Overview';
import { ResourceWrapper, ResponseCommon } from '@arcanna/models/utils';
import { Serializer } from '@arcanna/utils';
import axios, { AxiosResponse } from 'axios';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { overviewKeys } from 'src/_srcMUI/requests/Overview/keys';
import { showErrorNotification } from 'src/components/shared/utilities/notification';
import { config } from 'src/config';

type TUseFeedbackCoverageProps = {
  jobId: number;
  payload: FeedbackCoverageRequest;
};

function useFeedbackCoverage({ jobId, payload }: TUseFeedbackCoverageProps) {
  // OTHER HOOKS
  const { t } = useTranslation(['requests']);

  // SETUP
  const URL = useMemo(() => config.api.overview.feedbackCoverage, []);
  const axiosFunction = useCallback(
    (body: object) =>
      axios
        .post<ResourceWrapper<FeedbackCoverageResponse>>(URL, body)
        .then((response) => Serializer.getInstance().deserializeCommonResponse(response, FeedbackCoverageResponse)),
    [URL]
  );

  // PAYLOAD
  const payloadSerialized = useMemo(() => Serializer.getInstance().serializeObject(payload), [payload]);

  // QUERY
  return useQuery(
    overviewKeys.feedbackCoverage({ jobId: jobId, payload: payload, field: payload.field }),
    () => axiosFunction(payloadSerialized),
    {
      onError: (error: AxiosResponse<ResourceWrapper<ResponseCommon>>) => {
        if (error.data?.resource?.request?.reason) {
          showErrorNotification('Error', error.data.resource.request.reason);
        } else {
          showErrorNotification(
            t('requests:ecdiOverview.getFeedbackCoverage.error.title'),
            t('requests:ecdiOverview.getFeedbackCoverage.error.subtitle')
          );
        }
      }
    }
  );
}

export default useFeedbackCoverage;
