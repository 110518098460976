import moment from 'moment';

export const formattedData = <T extends Array<object & { date: string }>>(data: T) => {
  if (!data) {
    return [];
  }
  const dates = data.map((item) => moment(item.date));
  const minDate = moment.min(dates);
  const maxDate = moment.max(dates);
  const diffMillis = maxDate.diff(minDate);
  const oneDayMillis = moment.duration(1, 'day').asMilliseconds();
  const oneMonthMillis = moment.duration(1, 'month').asMilliseconds();
  const oneWeekMillis = moment.duration(1, 'week').asMilliseconds();
  const oneHourMillis = moment.duration(1, 'hour').asMilliseconds();
  const oneSecondMillis = moment.duration(1, 'seconds').asMilliseconds();

  if (diffMillis < oneSecondMillis) {
    return data.map((el) => ({ ...el, date: moment(el.date).format('HH:mm:SSSS'), fullDate: el.date }));
  }
  if (diffMillis < oneHourMillis) {
    return data.map((el) => ({ ...el, date: moment(el.date).format('HH:mm'), fullDate: el.date }));
  }
  if (diffMillis < oneDayMillis) {
    return data.map((el) => ({ ...el, date: moment(el.date).format('HH:mm'), fullDate: el.date }));
  } else if (diffMillis < oneWeekMillis) {
    return data.map((el) => ({ ...el, date: moment(el.date).format('ddd HH:mm'), fullDate: el.date }));
  } else if (diffMillis <= oneMonthMillis) {
    return data.map((el) => ({ ...el, date: moment(el.date).format('DD MMM'), fullDate: el.date }));
  } else {
    return data.map((el) => ({ ...el, date: moment(el.date).format('YYYY-MM-DD'), fullDate: el.date }));
  }
};
