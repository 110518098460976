import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('FilterRecord')
export class FilterRecord {
  @JsonProperty('field', String)
  field: string;

  @JsonProperty('operator', String)
  operator: string;

  @JsonProperty('value', String)
  value: string | string[];

  @JsonProperty('value_type', String)
  value_type?: string;

  constructor(field: string, operator: string, value: string | string[], value_type?: string) {
    this.field = field;
    this.operator = operator;
    this.value = value;
    this.value_type = value_type;
  }
}
