import * as React from 'react';
import { useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import LabelCircle from './LabelCircle';
import { Typography, Tooltip, useTheme, Stack } from '@mui/material';
import StyledCustomLabel from './StyledCustomLabel.styles';
import { Icon, EIcon } from '@arcanna/generic';
import { OverridableStringUnion } from '@mui/types';
import { Variant } from '@mui/material/styles/createTypography';
import { TypographyPropsVariantOverrides } from '@mui/material/Typography/Typography';

type CustomLabelProps = {
  hexColor: string;
  id?: string;
  name?: string | React.ReactNode;
  dataTestId?: string;
  strike?: boolean;
  lineHeight?: string;
  fontSize?: string;
  disabled?: boolean;
  disabledTooltip?: boolean;
  disabledTooltipOnOverflow?: boolean;
  disableColorCircle?: boolean;
  showBorderOnlyOnHover?: boolean;
  hasBorder?: boolean;
  onClick?: (id: string) => void;
  textStyle?: React.CSSProperties & { variant?: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides> };
  style?: React.CSSProperties;
  className?: string;
  width?: number; // used if we want to show ellipsis
  isConfirmed?: boolean;
  hasConsensusOverwritten?: boolean;
  consensusOverwrittenTooltip?: string;
  isHighlighted?: boolean;
  isEyeSlashed?: boolean;
};

function CustomLabel({
  hexColor,
  id,
  name = '',
  dataTestId = '',
  strike = false,
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string'.
  fontSize = null,
  lineHeight,
  disabled = false,
  disabledTooltip = true,
  disabledTooltipOnOverflow = true,
  disableColorCircle = false,
  hasBorder = false,
  showBorderOnlyOnHover = false,
  onClick = undefined,
  isConfirmed = false,
  hasConsensusOverwritten = false,
  consensusOverwrittenTooltip = '',
  textStyle,
  width = undefined,
  style,
  isHighlighted = false,
  className,
  isEyeSlashed
}: CustomLabelProps) {
  const textRef = useRef(null);
  const theme = useTheme();
  const disabledColor = theme.palette.secondary[400].toString();
  const color = disabled ? disabledColor : hexColor;
  const { t } = useTranslation(['feedback']);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const shouldTemporarlyShowBorder = ((showBorderOnlyOnHover && isHovered) || isHighlighted) && !disabled;

  const wrapperStyle = useMemo(() => {
    let computedStyle = style;
    if (hasBorder && shouldTemporarlyShowBorder) {
      computedStyle = {
        ...computedStyle,
        ...{
          justifyContent: 'center',
          border: `1px solid ${hexColor}`
        }
      };
    }
    return computedStyle;
  }, [hasBorder, shouldTemporarlyShowBorder, style, hexColor]);

  const getIsDisabledWrapperTooltip = () => {
    if (disabledTooltip) {
      return true;
    }

    if (disabledTooltipOnOverflow) {
      // eslint-disable-next-line
      return textRef?.current != null ? (textRef.current as any)?.scrollWidth <= (textRef.current as any)?.clientWidth : true;
    }

    return true;
  };

  const disabledWrapperTooltip = getIsDisabledWrapperTooltip();

  const onClickConditioned = function (labelId: string) {
    if (onClick && !disabled) {
      onClick(labelId);
    }
  };

  const hasEndIcon = hasConsensusOverwritten || (isConfirmed && !hasConsensusOverwritten);

  const actualWidth = width && hasEndIcon ? width - 24 : width;

  return (
    <Tooltip title={name} placement="top" disableHoverListener={disabledWrapperTooltip} arrow>
      <StyledCustomLabel
        style={{
          ...wrapperStyle
        }}
        isClickable={typeof onClick === 'function' && !disabled}
        hasPadding={hasBorder}
        className={className}
        // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig...
        onClick={() => onClickConditioned(id)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        data-label-id={id}
      >
        {!isEyeSlashed ? (
          <LabelCircle
            id={id}
            hexColor={color}
            isDisabled={disabled}
            shouldDisableColorCircle={disableColorCircle}
            onClick={onClickConditioned}
            style={style}
            size="small"
          />
        ) : (
          <Typography
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            component={'span'}
            fontSize={12}
            color={theme.palette.secondary[300]}
          >
            <Icon height={'12px'} width={'12px'} fontSize={'inherit'} color={'inherit'} name={EIcon.EyeSlashed} />
          </Typography>
        )}
        <Typography
          ref={textRef}
          variant={textStyle?.variant ?? 'subtitle1'}
          component="span"
          style={{
            color: isEyeSlashed ? theme.palette.secondary[300] : theme.palette.secondary[100],
            textDecoration: isEyeSlashed ? 'underline' : 'none',
            ...textStyle,
            width: actualWidth ? actualWidth - 12 : 'auto',
            fontSize: fontSize ?? 'default',
            lineHeight: lineHeight ?? 'default'
          }}
          className={cx({
            ['strike']: strike,
            ['ellipsis']: actualWidth !== undefined
          })}
          data-test-id={dataTestId}
        >
          {name}
        </Typography>
        {isConfirmed && !hasConsensusOverwritten && (
          <Tooltip title={t('feedback:arcannaDecisionConfirmed')} placement="top" arrow>
            <Stack component="span" direction="row" alignItems="center" justifyContent="center">
              <Icon name={EIcon.CheckCircleOutlined} fontSize="small" className="icon" />
            </Stack>
          </Tooltip>
        )}
        {hasConsensusOverwritten && (
          <Tooltip title={consensusOverwrittenTooltip || t('feedback:quickFilters.consensusOverwritten')} placement="top" arrow>
            <Stack component="span" direction="row" alignItems="center" justifyContent="center">
              <Icon name={EIcon.Lock} fontSize="small" className="icon" />
            </Stack>
          </Tooltip>
        )}
      </StyledCustomLabel>
    </Tooltip>
  );
}

export default CustomLabel;
