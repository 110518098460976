import { GenericOverviewRequest } from '@arcanna/models/Overview';

type TInvalidateRequestPayload = {
  jobId: number;
  payload: GenericOverviewRequest;
};

export const overviewKeys = {
  all: ['overview'] as const,
  allJobId: (jobId: number) => [...overviewKeys.all, jobId] as const,

  arcannaValues: ({ jobId, payload }: TInvalidateRequestPayload) =>
    [...overviewKeys.allJobId(jobId), 'arcannaValues', jobId, payload] as const,
  processedDetails: ({ jobId, payload }: TInvalidateRequestPayload) =>
    [...overviewKeys.allJobId(jobId), 'processedDetails', jobId, payload] as const,
  actionCenter: (jobId: number) => [...overviewKeys.allJobId(jobId), 'actionCenter', jobId] as const,
  accuracyMetrics: ({ jobId, payload }: TInvalidateRequestPayload) =>
    [...overviewKeys.allJobId(jobId), 'accuracyMetrics', jobId, payload] as const,
  decisionsMetrics: ({ jobId, payload }: TInvalidateRequestPayload) =>
    [...overviewKeys.allJobId(jobId), 'decisionsMetrics', jobId, payload] as const,
  decisionsUpdates: ({ jobId, payload }: TInvalidateRequestPayload) =>
    [...overviewKeys.allJobId(jobId), 'decisionsUpdates', jobId, payload] as const,
  usersPerformance: ({ jobId, payload }: TInvalidateRequestPayload) =>
    [...overviewKeys.allJobId(jobId), 'usersPerformance', jobId, payload] as const,
  decisionsBreakdown: ({ jobId, payload }: TInvalidateRequestPayload) =>
    [...overviewKeys.allJobId(jobId), 'decisionsBreakdown', jobId, payload] as const,
  feedbackCoverage: ({ jobId, payload, field }: { jobId: number; payload: GenericOverviewRequest; field?: string | null }) =>
    [...overviewKeys.allJobId(jobId), 'feedbackCoverage', field, jobId, payload, field] as const
};
