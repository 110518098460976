import { Any, JsonObject, JsonProperty } from 'json2typescript';
import { IntegrationAllowedValueRecord } from '../subcategory/IntegrationAllowedValueRecord';
import { IntegrationOptionRecord } from '../subcategory/IntegrationOptionRecord';

@JsonObject('IntegrationJobParameterRecord')
export class IntegrationJobBasicParameterRecord {
  @JsonProperty('field', String)
  field?: string | undefined;

  @JsonProperty('type', String)
  type?: string | undefined;

  @JsonProperty('caption', String)
  caption?: string | undefined;

  @JsonProperty('description', String, true)
  description?: string | undefined;

  @JsonProperty('true_caption', String)
  true_caption?: string | undefined;

  @JsonProperty('false_caption', String)
  false_caption?: string | undefined;

  @JsonProperty('required', Boolean)
  required?: boolean | undefined;

  // is optional
  @JsonProperty('allowed_values', Array<IntegrationAllowedValueRecord>(), true)
  allowed_values?: Array<IntegrationAllowedValueRecord> | undefined;

  @JsonProperty('dependency_field', String)
  dependency_field?: string | undefined;

  @JsonProperty('default', String)
  default?: string;

  @JsonProperty('min', Number)
  min?: number;

  @JsonProperty('max', Number)
  max?: number;

  @JsonProperty('dependency_value', String)
  dependency_value?: string | undefined;

  @JsonProperty('default_checked', Boolean)
  default_checked?: boolean | undefined;

  @JsonProperty('placeholder', String, true)
  placeholder?: string | undefined;

  @JsonProperty('visible', Boolean, true)
  visible?: boolean | true;

  @JsonProperty('multiclass', Boolean)
  multiclass?: boolean | false;

  @JsonProperty('multiple_selection', Boolean)
  multiple_selection?: boolean | false;

  @JsonProperty('span', Number)
  span: number | undefined;

  // used for fields with dependency fields. For example if boolean is selected I need it to occupy less space so
  // it makes space for the dependency field so it doesn't move around a lot on the page, basically feels like
  // the dependency field is hidden but still ocuppies it's space
  @JsonProperty('active_span', Number)
  active_span: number | undefined;

  @JsonProperty('is_future', Boolean)
  is_future: boolean | undefined;

  @JsonProperty('callback_endpoint', String)
  callback_endpoint: string | undefined;

  @JsonProperty('options', Any)
  // eslint-disable-next-line
  options: any | undefined;

  @JsonProperty('helper_text', String)
  helper_text: string | undefined;

  constructor(
    field: string,
    type: string,
    caption: string,
    required: boolean,
    default_checked: boolean,
    dependency_field: string,
    dependency_value: string,
    allowed_values: Array<IntegrationAllowedValueRecord> = [],
    defaultValue: string,
    min: number,
    max: number,
    placeholder: string,
    visible: boolean,
    multiclass: boolean,
    multiple_selection: boolean,
    span: number,
    active_span: number,
    is_future: boolean,
    callback_endpoint: string,
    helper_text: string
  ) {
    this.field = field;
    this.caption = caption;
    this.type = type;
    this.required = required;
    this.default_checked = default_checked;
    this.dependency_field = dependency_field;
    this.dependency_value = dependency_value;
    this.allowed_values = allowed_values;
    this.default = defaultValue;
    this.min = min;
    this.max = max;
    this.placeholder = placeholder;
    this.visible = visible;
    this.multiclass = multiclass;
    this.multiple_selection = multiple_selection;
    this.span = span;
    this.active_span = active_span;
    this.is_future = is_future;
    this.callback_endpoint = callback_endpoint;
    this.helper_text = helper_text;
  }
}

export class IntegrationParameterMappingRecord {
  @JsonProperty('type', String)
  type: string | undefined;

  @JsonProperty('selection', Boolean)
  selection: boolean | undefined;

  @JsonProperty('caption', String)
  placeholder: string | undefined;

  @JsonProperty('required', Boolean)
  required: boolean | undefined;

  @JsonProperty('options', Array<IntegrationOptionRecord>(), true)
  options: Array<IntegrationOptionRecord>;
}

export class IntegrationJobParameterRecord extends IntegrationJobBasicParameterRecord {
  @JsonProperty('extra_fields', [IntegrationJobBasicParameterRecord])
  extra_fields?: IntegrationJobBasicParameterRecord[];

  @JsonProperty('useTransition', Boolean)
  useTransition?: boolean;

  @JsonProperty('from_values', IntegrationParameterMappingRecord)
  from_values?: IntegrationParameterMappingRecord;

  @JsonProperty('to_values', IntegrationParameterMappingRecord)
  to_values?: IntegrationParameterMappingRecord;

  @JsonProperty('add_caption', String)
  add_caption?: string;

  constructor(
    field: string,
    type: string,
    caption: string,
    required: boolean,
    default_checked: boolean,
    dependency_field: string,
    dependency_value: string,
    allowed_values: Array<IntegrationAllowedValueRecord> = [],
    defaultValue: string,
    min: number,
    max: number,
    placeholder: string,
    visible: boolean,
    multiclass: boolean,
    multiple_selection: boolean,
    span: number,
    active_span: number,
    extra_fields: IntegrationJobBasicParameterRecord[],
    useTransition: boolean,
    is_future: boolean,
    callback_endpoint: string,
    helper_text: string
  ) {
    super(
      field,
      type,
      caption,
      required,
      default_checked,
      dependency_field,
      dependency_value,
      allowed_values,
      defaultValue,
      min,
      max,
      placeholder,
      visible,
      multiclass,
      multiple_selection,
      span,
      active_span,
      is_future,
      callback_endpoint,
      helper_text
    );
    this.extra_fields = extra_fields;
    this.useTransition = useTransition;
  }
}

export class IntegrationJobParameterRecordNew {
  @JsonProperty('params', [IntegrationJobParameterRecord])
  params?: IntegrationJobParameterRecord[];

  @JsonProperty('allow_throttling', Boolean)
  allow_throttling?: boolean;
}
