import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('JobTimeMetricsRecord')
export class JobTimeMetricsRecord {
  @JsonProperty('time_units', String)
  time_units: string;

  @JsonProperty('time_value', Number)
  time_value: number;

  @JsonProperty('hourly_rate', Number)
  hourly_rate: number;

  @JsonProperty('hourly_rate_currency', String)
  hourly_rate_currency: string;
}
