import { Cell, Pie, PieChart } from 'recharts';
import { CustomLabel as CustomLabelComponent } from '@arcanna/components';
import { Stack, Typography, useTheme } from '@mui/material';
import { CustomLabel } from 'src/components/shared/models';
import GenericOverviewCard from '../GenericOverviewCard';
import { useMemo } from 'react';
import { EmptyState, Spinner } from '@arcanna/generic';

export type LabelStatistic = {
  label: CustomLabel;
  count?: number;
  percent: number;
};

type DecisionsBreakdownProps = {
  labelsStats: LabelStatistic[];
  isLoading: boolean;
};

function DecisionsBreakdown({ labelsStats, isLoading }: DecisionsBreakdownProps) {
  const { palette } = useTheme();

  const totalDecisions = useMemo(
    () =>
      labelsStats.reduce((result: number, stat: LabelStatistic) => {
        return result + (stat.count ?? 0);
      }, 0),
    [labelsStats]
  );

  const statsPercentage = useMemo(() => {
    return (
      labelsStats?.reduce((result: number, stat: LabelStatistic) => {
        return result + (stat.percent ?? 0);
      }, 0) ?? 0
    );
  }, [labelsStats]);

  const pieData = useMemo(
    () =>
      statsPercentage > 0 ? labelsStats : [{ label: { hexColor: 'transparent', id: 'empty', name: 'empty' }, percent: 100 }],
    [labelsStats, statsPercentage]
  );

  if (labelsStats.length === 0 && !isLoading) {
    return (
      <GenericOverviewCard width="360px" title="Decisions Breakdown">
        <EmptyState title={'Start by giving feedback.'} subtitle={'You can give feedback from the Explorer section. '} />
      </GenericOverviewCard>
    );
  }

  return (
    <GenericOverviewCard position="relative" width="360px" title="Decisions Breakdown">
      {isLoading && <Spinner isOverlay />}
      <Stack spacing={'48px'}>
        <Stack direction={'row'} spacing={'16px'}>
          <PieChart width={140} height={140}>
            {statsPercentage > 0 ? (
              <Pie
                data={pieData}
                innerRadius={55}
                outerRadius={65}
                fill="#fff"
                paddingAngle={statsPercentage > 0 ? 10 : 0}
                dataKey="percent"
                startAngle={90}
                endAngle={450}
                stroke={statsPercentage === 0 ? palette.secondary[500] : 'none'}
                strokeWidth={statsPercentage === 0 ? 2 : 0}
                isAnimationActive={false}
              >
                {pieData?.map((label: LabelStatistic) => <Cell key={label.label.id} fill={label.label.hexColor} />)}
              </Pie>
            ) : (
              <g>
                <circle cx={70} cy={67} r={55} fill="none" stroke={palette.secondary[500]} strokeWidth={2} />
                <circle cx={70} cy={67} r={65} fill="none" stroke={palette.secondary[500]} strokeWidth={2} />
              </g>
            )}
          </PieChart>
          <Stack justifyContent={'center'}>
            <Typography variant={'subtitle1'} color={palette.secondary[200]} fontWeight={500}>
              Total Decisions
            </Typography>
            <Typography fontSize={'28px'} color={palette.secondary[100]} fontWeight={700}>
              {totalDecisions.toLocaleString()}
            </Typography>
          </Stack>
        </Stack>
        <Stack spacing={'4px'} sx={{ maxHeight: 121, overflow: 'auto' }}>
          {labelsStats.map((label: LabelStatistic) => (
            <Stack
              key={label.label.id}
              direction={'row'}
              justifyContent={'space-between'}
              sx={{ borderBottom: `1px solid ${palette.secondary[800]}` }}
            >
              <CustomLabelComponent
                width={200}
                textStyle={{ variant: 'subtitle2', color: palette.secondary[100] }}
                hexColor={label.label.hexColor}
                name={label.label.name}
              />
              <Typography color={palette.secondary[100]} variant={'subtitle2'}>
                {label.count?.toLocaleString()}(
                {label.percent.toLocaleString(undefined, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0
                })}
                %)
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </GenericOverviewCard>
  );
}

export default DecisionsBreakdown;
