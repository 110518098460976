import { Stack, Typography, useTheme } from '@mui/material';

type TContent = {
  dateStart: string;
  dateEnd: string;
  labelsData: { color: string; name: string; count: number; percentage: string }[];
  total: number;
};

type BarTooltipProps = {
  content?: TContent;
};

export default function BarTooltip({ content }: BarTooltipProps) {
  const { palette } = useTheme();
  if (content) {
    return (
      <Stack sx={{ backgroundColor: palette.secondary[1000] }} borderRadius={'8px'}>
        <Typography variant={'subtitle2'} fontWeight={400} color={palette.secondary[100]} padding={'4px 8px 4px 8px'}>
          Decisions made from {content.dateStart} to {content.dateEnd}
        </Typography>
        <Stack borderBottom={'1px solid ' + palette.secondary[800]} borderTop={'1px solid ' + palette.secondary[800]}>
          {content.labelsData.map((labelData) => (
            <Stack
              borderLeft={'3px solid ' + labelData.color}
              padding={'4px 8px 4px 8px'}
              key={labelData.name}
              direction={'row'}
              justifyContent={'space-between'}
            >
              <Typography variant={'subtitle2'} fontWeight={400} color={palette.secondary[100]}>
                {labelData.name}
              </Typography>
              <Typography variant={'subtitle2'} fontWeight={400} color={palette.secondary[100]}>
                {labelData.count.toLocaleString()} ({labelData.percentage}
                %)
              </Typography>
            </Stack>
          ))}
        </Stack>
        <Stack padding={'4px 8px 4px 8px'} direction={'row'} justifyContent={'space-between'}>
          <Typography variant={'subtitle2'} fontWeight={400} color={palette.secondary[100]}>
            Total
          </Typography>
          <Typography variant={'subtitle2'} fontWeight={400} color={palette.secondary[100]}>
            {content.total.toLocaleString()}
          </Typography>
        </Stack>
      </Stack>
    );
  }
  return null;
}
