import { Box, Stack, Typography, useTheme } from '@mui/material';
import moment from 'moment/moment';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from 'recharts';
import { AccuracyMetricsEntry } from '@arcanna/models/Overview';
import GenericOverviewCard from '../GenericOverviewCard';
import ArcannaDecisions from '../ArcannaDecisions';
import { EmptyState, Spinner } from '@arcanna/generic';
import { formattedData } from './utils';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

type TAccuracyMetricsProps = {
  data: AccuracyMetricsEntry[];
  isLoading: boolean;
};

function ArcannaAccuracy({ data, isLoading }: TAccuracyMetricsProps) {
  const { palette } = useTheme();
  const getCustomTooltip = (props: TooltipProps<ValueType, NameType>) => {
    const date = moment(props?.payload?.[0]?.payload.fullDate).format('MM-DD-YYYY HH:mm');

    return (
      <Box sx={{ backgroundColor: palette.secondary[1000], borderRadius: '8px', width: '200px' }}>
        <Typography
          sx={{ padding: '4px 8px', borderBottom: `1px solid  ${palette.secondary[800]}` }}
          variant={'subtitle1'}
          color={palette.secondary[100]}
        >
          {date}
        </Typography>
        <Stack
          justifyContent={'space-between'}
          direction={'row'}
          sx={{
            borderLeft: '3px solid ' + palette.info.light,
            borderBottom: `1px solid ${palette.secondary[800]}`,
            padding: '4px 8px'
          }}
        >
          <Typography variant={'subtitle1'} color={palette.secondary[100]}>
            Accuracy
          </Typography>
          <Typography variant={'subtitle1'} color={palette.secondary[100]}>
            {props?.payload?.[0]?.payload.accuracy}%
          </Typography>
        </Stack>
        <Stack
          justifyContent={'space-between'}
          direction={'row'}
          sx={{
            borderLeft: '3px solid ' + palette.warning.light,
            borderBottom: `1px solid ${palette.secondary[800]}`,
            padding: '4px 8px'
          }}
        >
          <Typography variant={'subtitle1'} color={palette.secondary[100]}>
            F1-score
          </Typography>
          <Typography variant={'subtitle1'} color={palette.secondary[100]}>
            {props?.payload?.[0]?.payload.f1_score}%
          </Typography>
        </Stack>
        <Box sx={{ height: '24px' }} />
      </Box>
    );
  };

  return (
    <GenericOverviewCard title="Arcanna's Accuracy, F1-score & Decisions" position={'relative'}>
      {isLoading && <Spinner isOverlay />}
      {data.length > 0 ? (
        <Stack direction={'row'} gap={'16px'} flexWrap={'wrap'}>
          <Stack>
            <Stack direction={'row'} gap={'24px'} marginBottom={'20px'}>
              <Stack direction={'row'} alignItems={'center'}>
                <Box
                  sx={{
                    width: '8px',
                    height: '8px',
                    borderRadius: '100%',
                    backgroundColor: palette.info.light,
                    marginRight: '8px'
                  }}
                />
                <Typography variant="subtitle2" fontWeight={400} color={palette.secondary[100]} marginRight={'4px'}>
                  Accuracy:
                </Typography>
                <Typography fontWeight={600}>{data.slice(-1)[0]?.accuracy}%</Typography>
              </Stack>
              <Stack direction={'row'} alignItems={'center'}>
                <Box
                  sx={{
                    width: '8px',
                    height: '8px',
                    borderRadius: '100%',
                    backgroundColor: palette.warning.light,
                    marginRight: '8px'
                  }}
                />
                <Typography variant="subtitle2" fontWeight={400} color={palette.secondary[100]} marginRight={'4px'}>
                  F1-score:
                </Typography>
                <Typography variant={'h4'}>{data.slice(-1)[0]?.f1_score}%</Typography>
              </Stack>
            </Stack>

            <ResponsiveContainer width={641} height={200}>
              <LineChart
                width={816}
                height={300}
                data={formattedData<AccuracyMetricsEntry[]>(data)}
                margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
              >
                <XAxis
                  interval={1}
                  dataKey={'date'}
                  stroke={palette.secondary[400]}
                  tickLine={false}
                  axisLine={false}
                  tickMargin={10}
                  tick={{ fontSize: '12px' }}
                />
                <YAxis
                  width={70}
                  tick={{ fontSize: '12px' }}
                  tickLine={false}
                  axisLine={false}
                  domain={[0, 100]}
                  stroke={palette.secondary[400]}
                  tickFormatter={(value) => `${value}%`}
                  tickMargin={20}
                />
                <CartesianGrid stroke={palette.secondary[800]} vertical={false} />
                <Line type="monotone" dataKey={'accuracy'} stroke={palette.info.light} dot={false} strokeWidth={2} />
                <Line type="monotone" dataKey={'f1_score'} stroke={palette.warning.light} dot={false} strokeWidth={2} />
                <Tooltip content={getCustomTooltip} cursor={false} />
              </LineChart>
            </ResponsiveContainer>
          </Stack>
          <Stack>
            <Box sx={{ width: '1px', height: '100%', backgroundColor: palette.secondary[800] }} />
          </Stack>
          <ArcannaDecisions />
        </Stack>
      ) : (
        <EmptyState
          title={'Start by training your AI model.'}
          subtitle={'Make sure you give feedback before training your AI model. You can give feedback from the Explorer section.'}
        />
      )}
    </GenericOverviewCard>
  );
}

export default ArcannaAccuracy;
