import { JsonObject, JsonProperty } from 'json2typescript';
import { ResponseCommon } from '../utils';

@JsonObject('DecisionsUpdatesResponse')
export class DecisionsUpdatesResponse extends ResponseCommon {
  @JsonProperty('confirmed_decisions', Number)
  confirmedDecisions: number;

  @JsonProperty('changed_decisions', Number)
  changedDecisions: number;
}
