import TopFiveHigh from './TopFiveHigh';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import TopFiveLow from './TopFiveLow';
import GenericOverviewCard from '../GenericOverviewCard';
import { EmptyState, Spinner } from '@arcanna/generic';
import FieldsSelect from './FieldsSelect';

export type TTopValue = {
  fieldValue: string;
  percent: number;
};

type TFeedbackCoverageProps = {
  fieldName: string | null;
  coveragePercentage: number;
  uniqueValues: number;
  topHighValues: TTopValue[];
  topLowValues: TTopValue[];
  onChangeField: (value: string) => void;
  isLoading: boolean;
};

function FeedbackCoverage({
  fieldName,
  topHighValues,
  topLowValues,
  onChangeField,
  coveragePercentage,
  uniqueValues,
  isLoading
}: TFeedbackCoverageProps) {
  const { palette } = useTheme();

  const FieldEmptyState =
    fieldName === null ? (
      <EmptyState
        title={'Start by selecting a field from the dropdown.'}
        subtitle={'You can see unique values and feedback statistics regarding that field. '}
      />
    ) : (
      <EmptyState
        title={'The selected field has no feedback coverage'}
        subtitle={`Start providing feedback for field ${fieldName}`}
      />
    );

  if (fieldName === null || (fieldName && topLowValues.length === 0 && topHighValues.length === 0)) {
    return (
      <GenericOverviewCard
        flex={1}
        title="Events with Feedback"
        position={'relative'}
        infoTooltipText="Find out how much data your feedback has covered."
      >
        {isLoading && <Spinner isOverlay />}

        <Stack direction={'row'} alignItems={'center'} spacing={'8px'}>
          <Typography variant={'h4'} color={palette.primary[500]} fontWeight={700}>
            {coveragePercentage.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })}
            %
          </Typography>
          <Typography variant={'subtitle2'} color={palette.secondary[100]} fontWeight={400}>
            of the values from
          </Typography>
          <FieldsSelect onChangeField={onChangeField} selectedField={fieldName ?? 'All fields'} />
        </Stack>
        {FieldEmptyState}
      </GenericOverviewCard>
    );
  }

  return (
    <GenericOverviewCard
      flex={1}
      title="Events with Feedback"
      infoTooltipText="Find out how much data your feedback has covered."
      position={'relative'}
    >
      {isLoading && <Spinner isOverlay />}
      <Stack spacing={'16px'}>
        <Stack direction={'row'} spacing={'8px'} alignItems={'center'}>
          <Typography lineHeight="36px" fontSize={'28px'} color={palette.primary[500]} fontWeight={700}>
            {coveragePercentage.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })}
            %
          </Typography>
          <Typography variant={'subtitle2'} fontWeight={400} color={palette.secondary[100]}>
            of Events containing
          </Typography>
          <FieldsSelect onChangeField={onChangeField} selectedField={fieldName} />
          <Typography variant={'subtitle2'} fontWeight={400} color={palette.secondary[100]}>
            have feedback
          </Typography>
        </Stack>
        <Box sx={{ width: '100%', height: '1px', backgroundColor: palette.secondary[800] }} />
        <Stack>
          <Typography variant={'subtitle1'} color={palette.secondary[200]} fontWeight={400}>
            There are {uniqueValues} unique values for this field.
          </Typography>
          <Typography variant={'subtitle1'} color={palette.secondary[200]} fontWeight={400}>
            Here are the most and least common.
          </Typography>
        </Stack>
        <Stack direction={'row'} justifyContent={'space-between'} spacing={'20px'}>
          <TopFiveHigh fieldName={fieldName} values={topHighValues} />
          <TopFiveLow fieldName={fieldName} values={topLowValues} />
        </Stack>
      </Stack>
    </GenericOverviewCard>
  );
}

export default FeedbackCoverage;
