import { ReactNode, useRef } from 'react';
import { createContext } from 'react';
import createECDIOverviewStore, { TECDIOverviewPageLoadFilters, TECDIOverviewStore } from './ECDIOverviewStore';

type TECDIOverviewContextProviderProps = {
  children: ReactNode;
  pageLoadFilters: TECDIOverviewPageLoadFilters;
};

export const ECDIOverviewContext = createContext<TECDIOverviewStore | null>(null);

function ECDIOverviewContextProvider({ children, pageLoadFilters }: TECDIOverviewContextProviderProps) {
  const store = useRef(
    createECDIOverviewStore({
      pageLoadFilters
    })
  ).current;

  return <ECDIOverviewContext.Provider value={store}>{children}</ECDIOverviewContext.Provider>;
}

export default ECDIOverviewContextProvider;
