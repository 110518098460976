import { Box, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { CustomLabel as CustomLabelModel } from '../../../../../components/shared/models';
import { CustomLabel } from '@arcanna/components';
import { useCallback, useState } from 'react';
import useCtrlKeyDetector from './useCtrlKeyDetector.hook';

type TCustomLabelsListProps = {
  labels: (CustomLabelModel & { active?: boolean })[];
  onHighlight: (labelId: string | null) => void;
  onHide: (labelId: string) => void;
  onShow: (labelId: string) => void;
  onHover: (labelId: string | null) => void;
};
export default function CustomLabelsList({ labels, onHighlight, onHide, onShow, onHover }: TCustomLabelsListProps) {
  const { palette } = useTheme();
  const { isCtrlPressed } = useCtrlKeyDetector();
  const [hoveredLabelId, setHoveredLabelId] = useState<string | null>(null);

  const [labelsList, setLabelsList] = useState<(CustomLabelModel & { active?: boolean })[]>(
    labels.map((label) => ({ ...label, active: true }))
  );

  const labelIsAlreadyHighlighted = useCallback(
    (labelId: string) => {
      if (labelsList.find((label) => label.id === labelId)?.active) {
        const inactiveLabels = labelsList.reduce((acc, label) => {
          if (label.id === labelId) {
            return acc;
          } else {
            if (!label.active) return acc + 1;
            return acc;
          }
        }, 0);
        const activeLabels = 1;
        if (inactiveLabels === labelsList.length - activeLabels) {
          return true;
        }
      }
      return false;
    },
    [labelsList]
  );
  const highlightLabel = useCallback(
    (labelId: string) => {
      const label = labelsList.find((l) => l.id === labelId);
      if (!label) {
        return;
      }
      if (labelIsAlreadyHighlighted(labelId)) {
        setLabelsList((prevState) => [...prevState].map((l) => ({ ...l, active: true })));
        onHighlight(null);
      } else {
        setLabelsList((prevState) =>
          [...prevState].map((l) => (l.id === labelId ? { ...l, active: true } : { ...l, active: false }))
        );
        onHighlight(labelId);
      }
    },
    [labelIsAlreadyHighlighted, labelsList, onHighlight]
  );

  const toggleLabel = useCallback(
    (labelId: string) => {
      setLabelsList((prevState) => {
        return [...prevState].map((label) => {
          if (label.id === labelId && label.active) {
            onHide(label.id);
          }
          if (label.id === labelId && !label.active) {
            onShow(label.id);
          }
          return label.id === labelId ? { ...label, active: !label.active } : label;
        });
      });
    },
    [onHide, onShow]
  );

  return (
    <Tooltip
      title={
        <Stack>
          <Typography variant={'subtitle2'} color={palette.secondary[100]} fontWeight={400}>
            Click to show/hide a decision
          </Typography>
          <Typography variant={'subtitle2'} color={palette.secondary[100]} fontWeight={400}>
            Ctrl + click to highlight a single decision
          </Typography>
        </Stack>
      }
      placement="top"
      arrow
    >
      <Stack spacing={'12px'} sx={{ overflowY: 'auto' }}>
        {labelsList.map((labelInfo: CustomLabelModel & { active?: boolean }) => {
          return (
            <Box
              onMouseEnter={() => {
                setHoveredLabelId(labelInfo.id);
                onHover(labelInfo.id);
              }}
              onMouseLeave={() => {
                setHoveredLabelId(null);
                onHover(null);
              }}
              key={labelInfo.id}
            >
              <CustomLabel
                onClick={(labelId) => {
                  if (!isCtrlPressed) {
                    toggleLabel(labelId);
                  }
                  if (isCtrlPressed) {
                    highlightLabel(labelId);
                  }
                }}
                key={labelInfo.id}
                hexColor={labelInfo.hexColor}
                id={labelInfo.id}
                name={labelInfo.name}
                textStyle={{ textDecoration: hoveredLabelId === labelInfo.id ? 'underline' : 'none' }}
                width={164}
                isEyeSlashed={!labelInfo.active}
              />
            </Box>
          );
        })}
      </Stack>
    </Tooltip>
  );
}
