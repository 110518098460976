import { EIcon } from '@arcanna/generic';
import { Typography } from '@mui/material';
import { Palette } from '@mui/material/styles';
import { EActionCenter, EActionCenterSubTypes } from '../../ActionCenter.types';

function getActionCardText(palette: Palette, type: EActionCenterSubTypes, value: number) {
  switch (type) {
    case EActionCenterSubTypes.OUTLIERS:
      return (
        <Typography variant="subtitle2" color={palette.secondary[100]}>
          {value}{' '}
          <Typography component="span" variant="inherit" color={palette.error[600]}>
            outliers
          </Typography>{' '}
          were detected.
        </Typography>
      );
    case EActionCenterSubTypes.LOW_CONFIDENCE_SCORE:
      return (
        <Typography variant="subtitle2" color={palette.secondary[100]}>
          {value} events with{' '}
          <Typography component="span" variant="inherit" color={palette.error[400]}>
            low
          </Typography>{' '}
          confidence score.
        </Typography>
      );
    case EActionCenterSubTypes.UNDECIDED_CONSENSUS:
      return (
        <Typography variant="subtitle2" color={palette.secondary[100]}>
          {value} events have{' '}
          <Typography component="span" variant="inherit" color="#A78BFA">
            undecided consensus
          </Typography>
          .
        </Typography>
      );
    case EActionCenterSubTypes.NEEDS_TRAINING:
      return (
        <Typography variant="subtitle2" color={palette.secondary[100]}>
          This job has new feedback and needs{' '}
          <Typography component="span" variant="inherit" color={palette.warning[600]}>
            training
          </Typography>
          .
        </Typography>
      );
    default:
      return <></>;
  }
}

const actionCardIconMap: Record<EActionCenter, EIcon> = {
  [EActionCenter.ALL]: EIcon.Feedback,
  [EActionCenter.GIVE_FEEDBACK]: EIcon.Feedback,
  [EActionCenter.ADJUST_FEEDBACK]: EIcon.Tool,
  [EActionCenter.TRAINING]: EIcon.Flash
};

const actionCardTitleMap: Record<EActionCenter, string> = {
  [EActionCenter.ALL]: 'All',
  [EActionCenter.GIVE_FEEDBACK]: 'Give Feedback',
  [EActionCenter.ADJUST_FEEDBACK]: 'Adjust Feedback',
  [EActionCenter.TRAINING]: 'Training'
};

export { getActionCardText, actionCardIconMap, actionCardTitleMap };
