import { EventExplorerFiltersFieldsRecord } from './../../../../../components/shared/models/event-explorer/EventExplorerFiltersFieldsRecord';
import {
  EFilterOperatorMultiple,
  EFilterOperatorWithValue,
  FILTER_OPPOSITES_MAP,
  TFilterItem,
  TFilterItemWithValue,
  TFilterOperator,
  TFilterItemWithValueType
} from './AdvancedFilters.types';
import { timestampToLocal } from '../../../../../utils/timestampToLocal';
import _ from 'lodash';
import {
  ARCANNA_NO_DECISION,
  ARCANNA_NO_DECISION_DISPLAY_VALUE,
  ARCANNA_RESULT_FIELD
} from 'src/components/pages/JobEventExplorer/utils';
import CustomLabelByJob from '@arcanna/pages/Feedback/components/FeedbackTable/models/CustomLabelByJob';
import { FeedbackFiltersFieldsRecord } from 'src/components/shared/models/feedback/FeedbackFiltersFieldsRecord';
import { UNDECIDED_CONSENSUS_ID, undecidedConsensus } from 'src/components/pages/Main/Jobs/helper';

export const transformValueByValueType = (filter: TFilterItem): string[] | string | null => {
  const filterValue = getFilterValue(filter);
  if (!filterValue) {
    return null;
  }
  const filterWithValueType = filter as TFilterItemWithValueType;
  if (filterWithValueType.valueType === 'date' && typeof filterValue === 'object') {
    return filterValue.map((value) => timestampToLocal(value));
  }
  if (filterWithValueType.valueType === 'date' && typeof filterValue === 'string') {
    return timestampToLocal(filterValue);
  }
  return filterValue;
};

export const getFilterValue = (filter: TFilterItem | undefined): string[] | string | null => {
  if (!filter) {
    return null;
  }

  if (getIsFilterWithValue(filter)) {
    return (filter as TFilterItemWithValue).value;
  }

  return null;
};

export const getAreFilterItemsEqual = (filter1: TFilterItem, filter2: TFilterItem) => {
  return (
    filter1.field === filter2.field && filter1.operator === filter2.operator && getAreFilterItemsWithSameValue(filter1, filter2)
  );
};

export const getIsOperatorWithValue = (operator: TFilterOperator) => {
  return (Object.values(EFilterOperatorWithValue) as string[]).includes(operator);
};

export const getIsOperatorWithMultipleValue = (operator: TFilterOperator) => {
  return (Object.values(EFilterOperatorMultiple) as string[]).includes(operator);
};

export const getIsFilterWithMultipleValue = (filter: TFilterItem) => {
  return (Object.values(EFilterOperatorMultiple) as string[]).includes(filter.operator);
};

export const getIsFilterWithValue = (filter: TFilterItem) => {
  return getIsOperatorWithValue(filter.operator) || getIsOperatorWithMultipleValue(filter.operator);
};

export const getIsFilterValid = (filter: TFilterItem) => {
  const filterValue = getFilterValue(filter);
  const shouldFilterHaveValue = getIsFilterWithValue(filter);
  const isValueFilled = typeof filterValue === 'string' ? !_.isNil(filterValue) : Boolean(filterValue?.length);
  const isFilterValueValid = shouldFilterHaveValue ? isValueFilled : !filterValue;

  return isFilterValueValid && filter.id && filter.status && filter.operator && filter.field;
};

export const getAreFilterItemsOpposites = (filter1: TFilterItem, filter2: TFilterItem) => {
  if (filter1.operator === filter2.operator) {
    return false;
  }

  return (FILTER_OPPOSITES_MAP[filter1.operator as EFilterOperatorWithValue] as string[])?.includes(filter2.operator);
};

export const getAreFilterItemsSimilar = (filter1: TFilterItem, filter2: TFilterItem) => {
  return filter1.operator === filter2.operator && filter1.field === filter2.field;
};

export const getAreFilterItemsWithSameValue = (filter1: TFilterItem, filter2: TFilterItem) => {
  const filter1Value = getFilterValue(filter1);
  const filter2Value = getFilterValue(filter2);

  if (!filter1Value && !filter2Value) {
    return true;
  }

  return typeof filter1Value === 'string' ? filter1Value === filter2Value : false;
};

export const getTransformedAdvancedFilters = (filters: TFilterItem[]) =>
  filters.filter((filter) => _.isEmpty(filter.status) || filter.status === 'active');

export const mapAdvancedFiltersToCustomLabelIds = (
  filters: TFilterItem[],
  jobInfoCustomLabels: CustomLabelByJob[],
  fieldNames: string[] | undefined = [ARCANNA_RESULT_FIELD]
) => {
  const extendedJobInfoCustomLabels = [
    ...jobInfoCustomLabels,
    {
      id: ARCANNA_NO_DECISION,
      name: ARCANNA_NO_DECISION_DISPLAY_VALUE
    },
    {
      id: UNDECIDED_CONSENSUS_ID,
      name: undecidedConsensus.name
    }
  ];

  return filters.map((filter) => {
    // for ARCANNA_RESULT_FIELD we should send the ID's of the custom labels of the job, hence this logic that is written below
    if (!fieldNames.includes(filter.field)) {
      return filter;
    }

    const isMultipleFilter = getIsFilterWithMultipleValue(filter);

    if (isMultipleFilter) {
      return {
        ...filter,
        value: ((filter as TFilterItemWithValue).value as string[]).map((customLabelName) => {
          const foundLabel = extendedJobInfoCustomLabels.find((customLabel) => customLabel.name === customLabelName);

          return foundLabel?.id ?? customLabelName;
        })
      };
    }

    const customLabelNameFromFilter = (filter as TFilterItemWithValue).value as string;
    const foundLabelInJobInfo = extendedJobInfoCustomLabels.find((customLabel) => customLabel.name === customLabelNameFromFilter);

    return {
      ...filter,
      value: foundLabelInJobInfo?.id ?? customLabelNameFromFilter
    };
  });
};

export const getFilterSourceByName = (
  name: string,
  fieldsList: FeedbackFiltersFieldsRecord[] | EventExplorerFiltersFieldsRecord[]
) => {
  const associatedFilter = fieldsList.find((filter) => filter.name === name || filter.source === name);
  if (associatedFilter != null) {
    return associatedFilter.source;
  }
  return '';
};
