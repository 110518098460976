import { useJobIdFromUrl } from '@arcanna/hooks';
import { useEventExplorerFiltersFields } from 'src/data-access';
import { Select } from '@arcanna/generic';

type TFieldsSelect = {
  onChangeField: (value: string) => void;
  selectedField: string;
};

export default function FieldsSelect({ onChangeField, selectedField }: TFieldsSelect) {
  const { jobId } = useJobIdFromUrl();

  const { data: fields } = useEventExplorerFiltersFields({ jobId });

  return (
    <Select
      sx={{ width: '200px' }}
      isTextInputIncluded={false}
      onChange={(value: string) => {
        onChangeField(value);
      }}
      value={selectedField}
      options={[{ name: 'All fields' }, ...(fields?.fields_list ?? [])]?.map((field) => ({
        label: field.name,
        value: field.name
      }))}
    />
  );
}
