import React from 'react';
import UsersPerformance from './UsersPerformance/UsersPerformance';
import GenericOverviewCard from '../GenericOverviewCard';

export type UserPerformance = {
  username: string;
  feedbackGiven: number;
  consensusPercent: number;
};

type UsersPerformance = {
  entries: UserPerformance[];
  isLoading: boolean;
};

type DecisionDetailsProps = {
  usersPerformance: UsersPerformance;
};

function DecisionsDetails({ usersPerformance }: DecisionDetailsProps) {
  return (
    <GenericOverviewCard position={'relative'}>
      <UsersPerformance entries={usersPerformance.entries} isLoading={usersPerformance.isLoading} />
    </GenericOverviewCard>
  );
}

export default DecisionsDetails;
