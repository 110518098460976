import { EActionCenter } from './ActionCenter.types';

function getActionCenterTabs() {
  return [
    {
      label: 'All',
      value: EActionCenter.ALL
    },
    {
      label: 'Give Feedback',
      value: EActionCenter.GIVE_FEEDBACK
    },
    {
      label: 'Adjust Feedback',
      value: EActionCenter.ADJUST_FEEDBACK
    },
    {
      label: 'Training',
      value: EActionCenter.TRAINING
    }
  ];
}

export { getActionCenterTabs };
