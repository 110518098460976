import { createColumnHelper } from '@tanstack/react-table';
import { UserPerformance } from '../DecisionsDetails';
import { Typography, useTheme } from '@mui/material';
import ConsensusBar from './ConsensusBar';

export default function useGetColumns() {
  const columnHelper = createColumnHelper<UserPerformance>();
  const { palette } = useTheme();

  const columns = [
    columnHelper.accessor('username', {
      id: 'username',
      header: 'Username',
      cell: ({ row }) => (
        <Typography variant={'subtitle2'} color={palette.secondary[100]}>
          {row.original.username}
        </Typography>
      ),
      size: 165,
      enableHiding: false,
      enableResizing: false
    }),
    columnHelper.accessor('feedbackGiven', {
      id: 'Feedback Given',
      header: 'Feedback Given',
      cell: ({ row }) => (
        <Typography variant={'subtitle2'} color={palette.secondary[100]}>
          {row.original.feedbackGiven.toLocaleString()}
        </Typography>
      ),
      size: 100,
      enableHiding: false,
      enableResizing: false
    }),
    columnHelper.accessor('consensusPercent', {
      id: 'Consensus',
      header: 'Consensus',
      cell: ({ row }) => <ConsensusBar consensus={row.original.consensusPercent} />,
      size: 300,
      enableHiding: false,
      enableResizing: false
    })
  ];
  return { columns };
}
