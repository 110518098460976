export const calculateMiddleDate = (dateStart: string, dateEnd: string) => {
  // Parse the input dates into Date objects
  const start = new Date(dateStart);
  const end = new Date(dateEnd);

  // Ensure both dates are valid
  if (isNaN(start.getTime()) || isNaN(end.getTime())) {
    throw new Error('Invalid date format');
  }

  // Calculate the midpoint timestamp
  const middleTimestamp = (start.getTime() + end.getTime()) / 2;

  // Convert the timestamp back to a Date object
  const middleDate = new Date(middleTimestamp);

  // Return the ISO string representation of the middle date
  return middleDate.toISOString();
};
