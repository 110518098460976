import { JsonObject, JsonProperty } from 'json2typescript';
import { ResponseCommon } from '../utils';

@JsonObject('ProcessedDetailsResponse')
export class ProcessedDetailsResponse extends ResponseCommon {
  @JsonProperty('decisions_made', Number)
  decisionsMade: number;

  @JsonProperty('processed_events', Number)
  processedEvents: number;

  @JsonProperty('processed_data_bytes', Number)
  processedDataBytes: number;
}
